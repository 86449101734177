import React from "react";
import Login from "../component/Login";

function Home() {
  return (
    <>
      <div className="login_form_box">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 col-lg-12 col-md-12 col-12 mx-auto">
              <div className="row">
                <div className="col-xl-6 col-lg-7 col-md-7">
                  <div className="login_box">
                    <Login />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-5 col-md-5">
                  <div className="information_box">
                    <h3>First time here?</h3>
                    <a href="#">Post your first ad</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
