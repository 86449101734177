import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { insertOTP } from "../store/slice/Appslice";
function OtpForm() {
  const dispatch = useDispatch();
  const loadingState = useSelector((state) => state.app.loading);
  const sendDetails = useSelector((state) => state.app.sendDetails);
  const userId = useSelector((state) => state.app.userId);
  const { id, name } = useParams();
  const [verifyId, setVarifyId] = useState(null);
  useEffect(() => {
    if (sendDetails != null) {
      setVarifyId(sendDetails.verify_id);
    } else {
      setVarifyId(id);
    }
  }, []);
  const { register, handleSubmit, reset, formState } = useForm();
  const { errors } = formState;
  function onSubmit(data) {
    const dataSend = {
      otp: data.otp,
      verifyId: verifyId,
      userId: userId || name,
    };
    dispatch(insertOTP(dataSend))
      .unwrap()
      .then((res) => {
        reset();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      <div className="mainOptp">
        <h4>Please enter this code here:</h4>
        <form className="mainFormBox" onSubmit={handleSubmit(onSubmit)}>
          <div className="singleInputBox">
            <div className="left">
              <input
                type="text"
                placeholder="Type in the number you received"
                className="form-control"
                {...register("otp", { required: true })}
              />
              {errors.otp?.type === "required" && (
                <small className="validationError">Please enter your OTP</small>
              )}
            </div>
            <div className="right">
              {loadingState && <button type="button">Verifying...</button>}
              {!loadingState && <button type="submit">Verify</button>}
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default OtpForm;
