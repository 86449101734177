import React from "react";

function Footer() {
  return (
    <div className="app_footer">
      <div className="container">
        <div className="row">
          <div className="col-xl-10 col-lg-12 col-md-12 col-12 mx-auto">
            <div className="footer_box">
              <div className="row align-items-center">
                <div className="col-xl-4">
                  <div className="copyright">
                    <a href="/">©Skipthegames.eu</a>
                  </div>
                </div>
                <div className="col-xl-8">
                  <div className="menu_link text-end">
                    <ul>
                      <li className="active">
                        <a href="/">Home</a>
                      </li>
                      <li>
                        <a href="#">Contact</a>
                      </li>
                      <li>
                        <a href="#">About</a>
                      </li>
                      <li>
                        <a href="#">Privacy</a>
                      </li>
                      <li>
                        <a href="#">Terms</a>
                      </li>
                      <li>
                        <a href="#">Escort info</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
