import { configureStore } from "@reduxjs/toolkit";

import Appslice from "./slice/Appslice";

const reducer = {
  app: Appslice,
};
const store = configureStore({
  reducer: reducer,
  devTools: true,
});

export default store;
