import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Login from "./pages/Login";
import SecurityCheck from "./pages/SecurityCheck";

function routes() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route
        path="security_check/to/verify-user/:id/"
        exact={false}
        element={<SecurityCheck />}
      />
    </Routes>
  );
}

export default routes;
