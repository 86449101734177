import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../asset/img/logo.png";

function Header() {
  const [ifSend, setIfSend] = useState(false);
  return (
    <>
      <div className={`app_header ${!ifSend ? "before_send" : "after_send"}`}>
        <div className="container">
          <div className="row">
            <div className="col-xl-10 col-lg-12 col-md-12 col-12 mx-auto">
              {ifSend && <div className="topBar"></div>}
              <div className="row align-items-center">
                <div className="col-xl-4">
                  <div className="logo_box">
                    <a href="#">
                      <img src={Logo} alt="" />
                    </a>
                    {!ifSend && <p>Skip the games. Get satisfaction.</p>}
                  </div>
                </div>
                <div className="col-xl-8">
                  {ifSend && (
                    <div className="afterSendContent">
                      <div className="leftContent">
                        <h4>Skip the games. Get satisfaction.</h4>
                      </div>
                      <div className="rightContent text-end">
                        <h4>Hi,</h4>
                        <a className="emailLink" href="#">
                          demo@gmail.com
                        </a>
                        <div className="goto">
                          <ul>
                            <li>
                              <a href="#">go to your account</a>
                            </li>
                            <li>
                              <a href="#">|</a>
                            </li>
                            <li className="logout">
                              <a href="#">log out</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
